import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";
import _values from "lodash-es/values";
import _merge from "lodash-es/merge";
import _keyBy from "lodash-es/keyBy";

var _templateObject;

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useMemo } from 'react';
import { Redirect } from '@reach/router';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { ArtistArtworks } from '@/artist/components/artist-artworks';
import { ArtistExhibitions } from '@/artist/components/artist-exhibitions';
import { ArtistPersonalInfo } from '@/artist/components/artist-personal-info';
import { ArtistPortfolio } from '@/artist/components/artist-portfolio';
import { ArtistProfileImage } from '@/artist/components/artist-profile-image';
import { BackLink } from '@/common/components/breadcrumb';
import { Content } from '@/common/components/content/content-element';
import { Layout } from '@/common/components/layout';
import { ScrollRestore } from '@/common/components/scroll-restore';
import { SEO } from '@/common/components/seo';
import { useAppInfo, useTranslation, useUri } from '@/common/hooks';
import { ArtistContainer, ArtistDescription, ArtistDetailsContainer, ArtistElementsBase, ArtistExternalLinksStyled, ArtistProfileDetails } from './artist';
import { jsx as ___EmotionJSX } from "@emotion/core";
var query = "2877008801";
var CuratorProfileQuery = gql(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  query CuratorProfileQuery($id: ID!) {\n    curatorProfile: node(id: $id) {\n      ... on CuratorProfile {\n        hostedArtworks {\n          nodes {\n            id\n            editionAvailableCount\n            editionSalesCount\n            editionPendingCount\n            editionSize\n            purchasePrice {\n              amount\n              formattedValue\n            }\n            isBuyable\n          }\n        }\n      }\n    }\n  }\n"])));

var CuratorPage = function CuratorPage(_ref) {
  var _curatorProfile$id, _curatorProfile$image, _curatorProfile$image2, _curatorProfile$image3, _curatorProfile$image4, _curatorProfile$image5, _curatorProfile$image6, _curatorProfile$descr, _curatorProfile$descr2, _curatorProfile$artwo3, _curatorProfile$artwo4, _curatorProfile$hoste;

  var data = _ref.data;

  var _useAppInfo = useAppInfo(),
      defaultMetaDescription = _useAppInfo.defaultMetaDescription;

  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  var _useUri = useUri(),
      uriHelper = _useUri.uriHelper;

  var _data$moonaapi = data === null || data === void 0 ? void 0 : data.moonaapi,
      curatorProfile = _data$moonaapi.curatorProfile;

  var _useQuery = useQuery(CuratorProfileQuery, {
    variables: {
      id: (_curatorProfile$id = curatorProfile === null || curatorProfile === void 0 ? void 0 : curatorProfile.id) !== null && _curatorProfile$id !== void 0 ? _curatorProfile$id : ''
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  }),
      curatorProfileData = _useQuery.data,
      refetch = _useQuery.refetch;

  if (!curatorProfile) {
    return ___EmotionJSX(Redirect, {
      to: "/404",
      noThrow: true
    });
  } // eslint-disable-next-line react-hooks/rules-of-hooks


  var hostedArtworks = useMemo(function () {
    if (!curatorProfileData) {
      var _curatorProfile$artwo;

      return (curatorProfile === null || curatorProfile === void 0 ? void 0 : (_curatorProfile$artwo = curatorProfile.artworks) === null || _curatorProfile$artwo === void 0 ? void 0 : _curatorProfile$artwo.nodes) || [];
    } else {
      var _curatorProfile$artwo2, _curatorProfileData$c;

      var mergedArtworks = _merge(_keyBy(curatorProfile === null || curatorProfile === void 0 ? void 0 : (_curatorProfile$artwo2 = curatorProfile.artworks) === null || _curatorProfile$artwo2 === void 0 ? void 0 : _curatorProfile$artwo2.nodes, 'id'), _keyBy(curatorProfileData === null || curatorProfileData === void 0 ? void 0 : (_curatorProfileData$c = curatorProfileData.curatorProfile) === null || _curatorProfileData$c === void 0 ? void 0 : _curatorProfileData$c.hostedArtworks.nodes, 'id'));

      return _values(mergedArtworks);
    }
  }, [curatorProfileData, curatorProfile]);
  var meta = [{
    property: 'og:url',
    content: uriHelper.ensureAbsoluteUrl(uriHelper.getCuratorUrl(curatorProfile.slug))
  }, {
    property: 'og:image',
    content: CONFIG.appUrl + (((_curatorProfile$image = curatorProfile.image) === null || _curatorProfile$image === void 0 ? void 0 : (_curatorProfile$image2 = _curatorProfile$image.childImageSharp) === null || _curatorProfile$image2 === void 0 ? void 0 : (_curatorProfile$image3 = _curatorProfile$image2.fluid) === null || _curatorProfile$image3 === void 0 ? void 0 : _curatorProfile$image3.src) || '')
  }, {
    property: 'twitter:image',
    content: CONFIG.appUrl + (((_curatorProfile$image4 = curatorProfile.image) === null || _curatorProfile$image4 === void 0 ? void 0 : (_curatorProfile$image5 = _curatorProfile$image4.childImageSharp) === null || _curatorProfile$image5 === void 0 ? void 0 : (_curatorProfile$image6 = _curatorProfile$image5.fluid) === null || _curatorProfile$image6 === void 0 ? void 0 : _curatorProfile$image6.src) || '')
  }, {
    property: 'og:description',
    content: (_curatorProfile$descr = curatorProfile.description) !== null && _curatorProfile$descr !== void 0 ? _curatorProfile$descr : defaultMetaDescription
  }, {
    name: 'twitter:description',
    content: (_curatorProfile$descr2 = curatorProfile.description) !== null && _curatorProfile$descr2 !== void 0 ? _curatorProfile$descr2 : defaultMetaDescription
  }];
  return ___EmotionJSX(Layout, null, ___EmotionJSX(SEO, {
    title: curatorProfile.curatorName || t('performanceData.curator'),
    meta: meta,
    description: curatorProfile.description || defaultMetaDescription
  }), ___EmotionJSX(ScrollRestore, {
    uri: uriHelper.getCuratorUrl(curatorProfile.slug)
  }), ___EmotionJSX(ArtistElementsBase, null, ___EmotionJSX(ArtistContainer, null, ___EmotionJSX(BackLink, {
    to: uriHelper.getExhibitionsUrl()
  }, ___EmotionJSX(FormattedMessage, {
    id: "general.backLinks.backToExhibitionsOverview"
  })), ___EmotionJSX(ArtistDetailsContainer, null, (curatorProfile === null || curatorProfile === void 0 ? void 0 : curatorProfile.image) && ___EmotionJSX(ArtistProfileImage, {
    artistName: curatorProfile.curatorName || t('performanceData.curator'),
    profileImage: curatorProfile.image
  }), ___EmotionJSX(ArtistProfileDetails, null, ___EmotionJSX(ArtistPersonalInfo, {
    artistProfiles: curatorProfile
  }), ___EmotionJSX(ArtistExternalLinksStyled, {
    links: curatorProfile === null || curatorProfile === void 0 ? void 0 : curatorProfile.externalLinks
  }), ___EmotionJSX(ArtistPortfolio, {
    portfolio: (_curatorProfile$artwo3 = curatorProfile === null || curatorProfile === void 0 ? void 0 : (_curatorProfile$artwo4 = curatorProfile.artworks) === null || _curatorProfile$artwo4 === void 0 ? void 0 : _curatorProfile$artwo4.nodes) !== null && _curatorProfile$artwo3 !== void 0 ? _curatorProfile$artwo3 : []
  }), ___EmotionJSX(ArtistDescription, null, ___EmotionJSX(Content, {
    source: curatorProfile.description
  }))))), ___EmotionJSX(ArtistExhibitions, {
    exhibitions: curatorProfile === null || curatorProfile === void 0 ? void 0 : (_curatorProfile$hoste = curatorProfile.hostedExhibitions) === null || _curatorProfile$hoste === void 0 ? void 0 : _curatorProfile$hoste.nodes,
    curatorName: curatorProfile.curatorName
  }), ___EmotionJSX(ArtistArtworks, {
    artworks: hostedArtworks,
    name: curatorProfile.curatorName,
    presentedBy: true,
    handleRefetchArtworks: refetch
  })));
};

CuratorPage.displayName = "CuratorPage";
export { CuratorPage as default, query };